import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Layout, Typography, Space } from "antd";
import { Navbar, Homepage, Exchanges, News, CryptoCurrencies, CryptoDetails } from "./components";
import "./App.css";

const App = () => {
  return (
    <div className="app">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="main">
        <Layout>
          <div className="routes">
            <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route path="/exchanges" element={<Exchanges />} />
                <Route path="/news" element={<News />} />
                <Route path="/cryptocurrencies" element={<CryptoCurrencies />} />
                <Route path="crypto/:coinId" element={<CryptoDetails />} />
            </Routes>
          </div>
        </Layout>
      <div className="footer">
        <Typography.Title level={5} style={{ color: "white", textAlign: "center" }}>
          IP Checker <br />
          All rights reserved
        </Typography.Title>
        <Space>
          <Link to="/">Home</Link>
          {/* <Link to="/exchanges">Exchanges</Link>
          <Link to="/news">News</Link>
          <Link to="/cryptocurrencies">Cryptocurrencies</Link> */}
          </Space>
        </div>
      </div>
    </div>
  );
};

export default App;
