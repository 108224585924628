import React, { useState } from 'react';
import { useGetIpInfoQuery } from '../services/checkMyIp';
import { Loader } from './Loader';

const Homepage = () => {
  const [ip, setIp] = useState('');
  const [submittedIp, setSubmittedIp] = useState('');
  const { data, error, isLoading } = useGetIpInfoQuery(submittedIp, { skip: !submittedIp });

  const handleInputChange = (e) => {
    setIp(e.target.value);
  };

  const handleSubmit = () => {
    setSubmittedIp(ip);
    setIp(''); // Reset input after submission
  };

  const handleCheckMyIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const result = await response.json();
      setSubmittedIp(result.ip);
      setIp(''); // Reset input after checking own IP
    } catch (err) {
      console.error('Failed to fetch IP:', err);
    }
  };


  return (
    <div>
      <h1>IP Information Lookup</h1>
      <input
        type="text"
        value={ip}
        onChange={handleInputChange}
        placeholder="Enter IP address"
      />
      <button onClick={handleSubmit}>Check IP</button>
      <button onClick={handleCheckMyIp}>Check My IP</button>

      {isLoading && <Loader />}
      {error && <div>Error: {error.message}</div>}

      {data ? (
        <div>
          <h2>IP Information</h2>
          <p>IP: {data.ip}</p>
          <p>City: {data.city}</p>
          <p>Region: {data.region}</p>
          <p>Country: {data.country}</p>
          <p>Country Name: {data.country_name}</p>
          <p>Country Code: {data.country_code}</p>
          <p>Country Code ISO3: {data.country_code_iso3}</p>
          <p>Currency: {data.currency}</p>
          <p>Currency Name: {data.currency_name}</p>
          <p>Timezone: {data.timezone}</p>
          <p>UTC Offset: {data.utc_offset}</p>
          <p>Country Calling Code: {data.country_calling_code}</p>
          <p>Currency Symbol: {data.currency_symbol}</p>
          <p>Languages: {data.languages}</p>
          <p>Country Area: {data.country_area}</p>
        </div>
      ) : (
        submittedIp
      )}
    </div>
  );
};

export default Homepage;
